$orange: #FBA600;
.Approval
{
    padding: 0px !important;
    .window-block
    {
        max-width: calc(100% - 80px);
        margin-left: 40px;
        margin-right: 40px;

        .mx-auto
        {
            max-width: 500px;
        }

        .description-content
        {
            text-align: justify;
        }

        .button-comment
        {
            position: relative;
            margin-top: -20px;
            right: 15px;
            float: right;
            background-color: $orange;
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 90px;
            cursor: pointer;

            .icon
            {
                color: #FFF;
                line-height: 35px;
                font-size: 16px;
            }
        }

        .comments
        {
            height: 150px;
            resize: none;
        }
    }
}