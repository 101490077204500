$blue-primary: #2C70B9;
$blue-primary-bg: #2C70B922;
$grey-secondary: #3d3d3d;
$grey-secondary-bg: #dddddd;
$grey-secondary-hover: #e1e1e1;

.Catalogs
{
    background-color: #fff;
    .wrapper
    {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    
    .window-block
    {
        top: 25vh; 
        max-width: 532px;
        position: relative;
        width:100%;
    
        .btn-primary
        {
            .size-20
            {
                vertical-align:-3px
            }
        }
    }

    .wrap-cards
    {
        margin-top: 60px;

        .catalogs{
            text-decoration: none !important;
            color: #000;
            font-weight: bold;
        }

        .card
        {
            margin-top: 10px;
            padding: 20px;
            min-height: 100px;
            line-height: 30px;

            &:hover
            {
                background-color: $blue-primary;
                color: #fff;
                svg
                {
                    color: #fff !important;
                }
            }
        }
    }

    .btn-export-excel {
        background-color: rgb(40, 180, 99) !important;
        color: white !important;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 5px;
      }

      

    .list
    {
        margin-top: 40px;
    }

    .wrap-dependency
    {
        margin-top: 40px;
        background-color: #fff;
        min-height: 240px;
        padding: 40px;

        .row
        {
            margin-bottom: 20px;
        }
        

        label
        {
            text-align: left;
        }
    }
}