.main-wrap {
    min-width: 100vw;
    min-height: 100vh;
    background-color: #EEE !important;
    margin-top: 0;
    overflow-y: none;
    position: relative;
    display: flex;

    @media (min-width: 480px) {
        min-height: 85vh; // Ajuste para pantallas medianas
    }

    @media (min-width: 768px) {
        min-height: 90vh; // Valor estándar para tablets y superiores
    }

    @media (min-width: 1024px) {
        min-height: 95vh; // Ampliar para pantallas grandes
    }

    @media (min-width: 1400px) {
        min-height: 100vh; // Máxima altura para pantallas grandes
    }

    .welcome-message {
        min-height: 40vh;
        height: 40vh auto;
        max-height: 50vh;
        text-align: center;
        background-color: #ffffff;
        position: relative;
        margin-top: 25vh;

     
        

        img {
            margin-top: 5vh;
            margin-bottom: 5vh;
            

            
        }

        h4 {
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 20px;

            
        }

        p {
            text-align: justify;

           

            

            
        }
    }
}
