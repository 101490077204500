body
{
    overflow-x: hidden;
}

.App {
  text-align: center;
}

.not-padding
{
    padding-left: 0px;
    padding-right: 0px;
}

.App-header
{
    min-height: 100vh;
}

.no-link
{
    text-decoration: none !important;
}

$primary: blue;
$blue-primary: #2C70B9;
$blue-link: #e4f6ff;
$dark-grey: #212121;
$grey-dark-border: #616161;
$grey: #585858;
$grey-light: #F9F9F9;
$green-success:#4CAF50;
$yellow-warning: #E79B00;
$yellow-warning-light: #f8f0e0;
$button-light: #e0e0e0;

$light-grey: #585858;
$white-grey: #F7F7F7;
$light-white: #F0F0F0;
$grey-hover: #6F6F6F;
$grey-dark: #2B2B2B;
$grey: #CDCCCC;
$color-active: #207EEC;

// Texts
$text-primary: #585858;
$text-white: white;

$font-size: 18px;
$text-size: 12px;
$font-input: 14px;

/* Override Bootstrap */


.btn-link-custom
{
    background-color: $blue-link !important;
    font-weight: bold;
}

.btn-light
{
    background-color: $button-light !important;
    border-color: $button-light !important;
}

a {
    font-size: $text-size;
    transition: all .2s;
    color: $text-primary;
    text-decoration: underline !important;
    &:hover {
        color: $primary
    }
}

.left-bar ul li {
    a {
        height:22px;
        cursor: pointer;
        transition: all .2s;
        color: $text-white;
    }
}

.btn.btn-primary {
    background-color: $blue-primary !important;
    border-color: $blue-primary !important;
    &:hover {
        background-color: lighten($blue-primary, 30%);
        text-decoration: underline;
    }
    &:active {
        background-color: $blue-primary !important;
    }
    &.active {
        background-color: lighten($blue-primary, 8%) !important;
        box-shadow: 0px 0px 1px $dark-grey;
        text-decoration: underline;
    }
}

.btn {
    text-transform: none;
    &.h-30 {
        padding-top: 9px;
        padding-bottom: 9px;
        font-size: 14px;
        height: 30px;
        line-height: 12px;
    }
    &.w-230 { width: 230px }
}

.btn-success {
    transition: all .2s;
    &:hover {
        text-decoration: none;
    }
}

.modal .modal-content {
    border:1px solid gray;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
}

/* Miscellaneous */

body, label {
    font-size: $font-size;
    font-family: "Nunito Sans", Helvetica, Arial;
    font-weight: 400;
    // background-color: #F7F7F7;
}

.bold { font-weight: 700; }

.size-8 { font-size: 8px }
.size-10 { font-size: 10px }
.size-12 { font-size: 12px }
.size-14 { font-size: 14px !important }
.size-16 { font-size: 16px }
.size-18 { font-size: 18px }
.size-20 { font-size: 20px }
.size-24 { font-size: 24px !important }
.size-28 { font-size: 28px !important }
.size-32 { font-size: 32px }
.size-36 { font-size: 36px }
.size-42 { font-size: 42px }

.bg-blue-primary { background-color: $blue-primary !important }
.bg-dark-grey { background-color: $dark-grey !important }
.bg-grey-light { background-color: $grey-light !important }

.text-light-grey { color: $light-grey }
.text-grey { color: $dark-grey }
.c-pointer { cursor: pointer }
.unselectable {
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}

.shadow {
    box-shadow: 0px 3px 6px #00000029;
}

// .avatar {
//     display:inline-block;
//     width:24px;
//     height:24px;
//     font-weight: bold;
//     text-align: center;
//     border-radius: 50%;
//     background-color: black;
//     color:white;
// }

.btn.light-blue {
    background-color: lighten($blue-primary, 50%) !important;
    font-weight: bold;
    color: $blue-primary;
}

.circle-success {
    background-color: $green-success;
    width:60px;
    height: 60px;
    color: white;
    border-radius: 50%;
    .icon {
        font-size: 42px;
        line-height: 60px;            
    }
}

.circle-warning {
    background-color: $yellow-warning;
    width:60px;
    height: 60px;
    color: white;
    border-radius: 50%;
    .icon {
        font-size: 42px;
        line-height: 60px;            
    }
}

.status-label {
    &.secondary {
        background-color: lighten($dark-grey, 75%);
        color: $dark-grey;
    }
    &.primary {
        color: $blue-primary;
        background-color: lighten($blue-primary, 45%);
    }
    &.success {
        color: $green-success;
        background-color: lighten($green-success, 43%);
    }
    &.warning {
        color: $yellow-warning;
        background-color: $yellow-warning-light;
    }
}

/* Forms */

form {
    input {
        height: 40px;
        font-size: 12px !important;
    }
}

.form-control {
    font-size: $font-input !important;
    label {
        text-align:left;
    }
    input {
        border:0;
        -webkit-border-radius: 4px !important;
        -moz-border-radius: 4px !important;
        border-radius: 4px !important;
    }
}

.input-icon {
    font-size:22px;
    position: relative;
    top: -35px;
    right: 3px;
    margin-top: 3px;
    color: $grey;
}

input[type="checkbox"] {
    position: relative;
    height: 12px;
    top: -6px;
}

.checkbox-round {
    transition: all .15s ease-in-out !important;
    position: relative !important;
    top: -6px !important;
    height: 15px !important;
    width: 15px !important;
    border: 1px solid $grey-dark-border !important;
    vertical-align: -8px;
    border-radius: 50% !important;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color: $blue-primary;
    border-color: $blue-primary;
}

/* Templates */

.top-bar {
    position:fixed;
    background-color: $text-white;
    top: 0px;
    width:100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 60px;
    z-index:100;
}

.wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.wrapper > .left-bar {
    width:220px;
}
.wrapper > .data-content {
    flex: 1;
    background-color: $white-grey;
}

/* Material icons */

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
        local('MaterialIcons-Regular'),
    }