.wrap-login {
    min-height: 100vh;
}

#login {
    overflow-y: auto;

    .login-top {
        // Altura predeterminada para pantallas pequeñas
        height: 150px;

        // Media queries para diferentes anchos de pantalla
        @media (min-width: 480px) {
            height: 150px; // Ajustar ligeramente para pantallas medianas
        }

        @media (min-width: 768px) {
            height: 175px; // Altura estándar para tablets y superiores
        }

        @media (min-width: 1140px) {
            height: 200px; // Altura ampliada para pantallas más grandes
        }

        @media (min-width: 1400px) {
            height: 220px; // Máxima altura en pantallas grandes
        }
    }

    .modal-login {
        h5 {
            font-size: 32px;
        }

        &.modal-content {
            margin: 0 auto;
            width: 500px;
            padding: 0;
            border-radius: 0 !important;
            border: 0;
        }

        #sent {
            width: 380px !important;
            margin: 0 auto !important;

            .icon {
                background-color: #daf1fd !important;
                color: #2C70B9 !important;
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 90px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
            }

            a {
                text-decoration: none !important;
            }
        }
    }

    .session-check {
        vertical-align: 24px;
        line-height: 0;
    }
}


.bg-image {
    background-color: #EEEEEE;
    min-height: 90vh;
}