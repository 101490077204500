$blue-link: #e3f5ff;
$blue-primary: #2C70B9;
$green-primary: #3BA745;
$orange-badge-bg: #fff3d3;
$orange-badge-font: #ffa807;
$orange-custom-badge-bg: #ffa807;
$orange-custom-badge-font: #fff;
$secondary-badge-bg: #dddddd;
$secondary-badge-font: #3d3d3d; 
$success-badge-bg: #dceee1;
$success-badge-font: #28a745;

.PPIS {
    background-color: #fff;

    .wrapper {
        padding-left: 0px !important;
        padding-right: 0px !important;

        @media (min-width: 768px) {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }

        @media (min-width: 1140px) {
            padding-left: 30px !important;
            padding-right: 30px !important;
        }
    }

    .window-block {
        top: 25vh;
        max-width: 532px;
        position: relative;
        width: 100%;

        @media (min-width: 768px) {
            max-width: 600px;
        }

        @media (min-width: 1140px) {
            max-width: 750px;
        }

        .btn-primary {
            .size-20 {
                vertical-align: -3px;
            }
        }
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .mdb-datatable {
        margin: 0px 10px;

        @media (min-width: 768px) {
            margin: 0px 20px;
        }

        @media (min-width: 1140px) {
            margin: 0px 30px;
        }

        .table-hover tbody tr:hover {
            background-color: #f0f0f0 !important;
            cursor: pointer;
        }
    }

    tbody {
        .badge {
            padding: 5px 15px;

            @media (min-width: 768px) {
                padding: 8px 20px;
            }

            @media (min-width: 1140px) {
                padding: 10px 25px;
            }
        }

        .badge-primary {
            background-color: $blue-link;
            color: $blue-primary;
        }

        .badge-warning {
            background-color: $orange-badge-bg;
            color: $orange-badge-font;
        }

        .badge-secondary {
            background-color: $secondary-badge-bg;
            color: $secondary-badge-font;
        }

        .badge-success {
            background-color: $success-badge-bg;
            color: $success-badge-font;
        }

        .warning-custom {
            background-color: $orange-custom-badge-bg;
            color: $orange-custom-badge-font;
        }

        .info-custom {
            background-color: $green-primary;
            color: $orange-custom-badge-font;
        }

        .comments {
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 90px;
            text-align: center;
            padding: 5px !important;

            @media (min-width: 768px) {
                width: 40px;
                height: 40px;
                line-height: 40px;
            }

            @media (min-width: 1140px) {
                width: 45px;
                height: 45px;
                line-height: 45px;
            }

            .icon {
                font-size: 18px;
                text-align: center;
                color: #fff;

                @media (min-width: 768px) {
                    font-size: 20px;
                }

                @media (min-width: 1140px) {
                    font-size: 22px;
                }
            }
        }
    }

    #datatable {
        width: 99%;
        padding: 15px;

        @media (min-width: 768px) {
            width: 98%;
            padding: 20px;
        }

        @media (min-width: 1140px) {
            width: 97%;
            padding: 30px;
        }
    }
}
