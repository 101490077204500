.wrap-login {
    min-height: 100vh;
}

#login {
    overflow-y: auto;

    .login-top {
        height: 175px;

        @media (min-width: 480px) {
            height: 150px; // Ajustar ligeramente para pantallas medianas
        }

        @media (min-width: 768px) {
            height: 175px; // Altura estándar para tablets y superiores
        }

        @media (min-width: 1140px) {
            height: 200px; // Altura ampliada para pantallas más grandes
        }

        @media (min-width: 1400px) {
            height: 220px; // Máxima altura en pantallas grandes
        }
    }

    .modal-login {
        h5 {
            font-size: 32px;

            @media (min-width: 480px) {
                font-size: 28px; // Reducir tamaño para pantallas medianas
            }

            @media (min-width: 768px) {
                font-size: 32px; // Tamaño estándar para tablets
            }

            @media (min-width: 1140px) {
                font-size: 36px; // Ampliar tamaño para pantallas grandes
            }

            @media (min-width: 1400px) {
                font-size: 40px; // Máximo tamaño para pantallas grandes
            }
        }

        &.modal-content {
            margin: 0px auto;
            width: 500px;
            padding: 0;
            border-radius: 0px !important;
            border: 0;

            @media (min-width: 480px) {
                width: 90%; // Ajuste para pantallas medianas
            }

            @media (min-width: 768px) {
                width: 500px; // Tamaño estándar para tablets y superiores
            }

            @media (min-width: 1140px) {
                width: 550px; // Ampliar tamaño para pantallas grandes
            }

            @media (min-width: 1400px) {
                width: 600px; // Máximo tamaño para pantallas grandes
            }
        }

        #login-form {
            width: 280px;
            margin: 0px auto;

            @media (min-width: 480px) {
                width: 90%; // Ajuste para pantallas medianas
            }

            @media (min-width: 768px) {
                width: 280px; // Tamaño estándar para tablets y superiores
            }

            @media (min-width: 1140px) {
                width: 300px; // Ampliar tamaño para pantallas grandes
            }

            @media (min-width: 1400px) {
                width: 320px; // Máximo tamaño para pantallas grandes
            }
        }
    }

    .session-check {
        vertical-align: 24px;
        line-height: 0;

        @media (min-width: 480px) {
            vertical-align: 20px; // Ajuste para pantallas medianas
        }

        @media (min-width: 768px) {
            vertical-align: 24px; // Valor estándar para tablets y superiores
        }

        @media (min-width: 1140px) {
            vertical-align: 28px; // Ampliar espaciado para pantallas grandes
        }

        @media (min-width: 1400px) {
            vertical-align: 32px; // Máximo espaciado para pantallas grandes
        }
    }
}

.bg-image {
    background-color: #EEEEEE;
    min-height: 90vh;

    @media (min-width: 480px) {
        min-height: 85vh; // Ajuste para pantallas medianas
    }

    @media (min-width: 768px) {
        min-height: 90vh; // Valor estándar para tablets y superiores
    }

    @media (min-width: 1140px) {
        min-height: 95vh; // Ampliar para pantallas grandes
    }

    @media (min-width: 1400px) {
        min-height: 100vh; // Máxima altura para pantallas grandes
    }
}
