.data-content{

    .report-card
    {
        background-color: #FFF;
        margin-top: 20px;
        margin-bottom: 20px;

        h3
        {
            margin-top: 20px;
            margin-bottom: 40px;
        }
    }
    canvas{
        max-height: 600px !important;
        max-width: 80% !important;
        margin-left: 10% !important;
    }
}