$blue-primary: #2C70B9;
$blue-primary-bg: #2C70B922;
$grey-secondary: #3d3d3d;
$grey-secondary-bg: #dddddd;
$grey-secondary-hover: #e1e1e1;

.topWrap
{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.TopBar
{
    position: relative;
    background-color: white;
    display:grid;
    grid-template-rows: 1fr 1fr;
    height:100px;

    .btn-report-custom
    {
        margin-top: 6px;
        background-color:transparent;
        color: #808080;
        border: solid #2C70B9;
        border-width: 0px 0px 3px 0px;
        border-radius: 0px;

        &:hover
        {
            color: #000;
            border: solid #2C70B9;
            border-width: 0px 0px 3px 0px;
            border-radius: 0px;
        }
    }

    a
    {
        margin-right: 10px;
        text-decoration: none !important;
        color: #000;

        &:hover
        {
            color: #777;
        }
    }
    .wrap-format
    {
        margin-top: 10px;
        border: solid thin #d6d6d6 !important;
        border-radius: 5px;
        height: 30px;
        line-height:20px;
        padding: 5px 10px;
        color: #000 !important;

        span
        {
            color: #007bff !important;
        }
    }

    .options-bar
    {
        padding: 0px !important;
        bottom: 0;
        height: 40px !important;
        line-height: 40px !important;

        .custom-badge
        {
            background-color: transparent;
            height: 30px;
            line-height: 20px !important;
            text-align: center;
            padding: 0px 15px;
            border-radius: 5px;
            margin-top: 10px;
        }

        .primary
        {
            background-color: $blue-primary-bg;
            color: $blue-primary;
        }

        .secondary,
        .btn-secondary
        {
            background-color: $grey-secondary-bg;
            color: $grey-secondary;
            border-color: $grey-secondary-bg;
        }
        .btn-secondary
        {
            &:hover
            {

            }
        }

        .buttons-wrap
        {
            min-width: 260px !important;

            .btn
            {
                min-width: 125px !important;
                padding: 2px 10px !important;
                line-height: 20px;
                margin-top: 10px;
            }
        }
    }
}