$orange: #FBA600;

.NewPPI
{
    .wrapper
    {
        padding-left: 0px;
        padding-right: 0px;
    }
    .window-block
    {
        top: 15px; 
        max-width: 800px;
        position: relative;
        width:100%;
        
        .mx-auto
        {
            max-width: 500px;
        }
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    form.form-gob {
        label {
            background-color: transparent;
            text-align:left;
            font-size:14px;
            font-weight: bold;
        }
        input.form-control {
            height: 30px;
        }

        a
        {
            text-decoration: none !important;
        }

        input[type=number],
        input[type=number]:focus
        {
            outline: none !important;
        }
    }

    textarea,
    .textarea
    {
        resize: none;
    }


    .modal-body
    {
        padding: 30px 100px;
    }

    .amount-txt,
    .amount-txt:focus
    {
        outline: none;
    }
    .button-comment
    {
        position: relative;
        margin-top: -20px;
        right: 15px;
        float: right;
        background-color: $orange;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 90px;
        cursor: pointer;

        .icon
        {
            color: #FFF;
            line-height: 35px;
            font-size: 16px;
        }
    }

    .comments
    {
        height: 150px;
        resize: none;
    }
}