$blue-background: rgba(44, 112, 185, 0.1);
$blue-border: #2c70b9;
$notes: rgb(44, 112, 185);
$grey-dark-border: rgba(97, 97, 97, 0.25);
$danger: #C41622;
$danger-hover: #ff1f06;
$observations: #ff0411c2;
$observations-border: #ffcccc;

.NotaTecnica
{
    margin-top: 20px;
    font-size: 12px;
    .row
    {
        flex-wrap:wrap-reverse;
    }
    .title-section
    {
        background-color: $blue-background;
        min-height:40px;
        border-top: solid 4px $blue-border;
        font-weight: bold;
        text-align: left;
        line-height: 40px;
        font-size: 18px;
    }

    .nota-nueva {
        background-color: #e9ecef;
        display: inline-block; /* Cambia el contenedor a inline-block para ajustar al contenido */
        min-height: 30px;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        text-align: justify;
        line-height: 1; /* Ajuste del line-height */
        font-size: clamp(10px, 1.5vw, 14px); /* Ajuste más pequeño del tamaño de fuente */
        padding: 10px;
        max-width: 100%; /* Limita el ancho máximo para que no se desborde */
        overflow: hidden; /* Oculta cualquier desbordamiento */
       
        
    }
    .observations-section
    {
        background-color: $observations-border;
        min-height:35px;
        border-top: solid 4px $observations;
        font-weight: bold;
        text-align: left;
        line-height: 35px;
        font-size: 14px;
    }
    
    .wrap-section
    {
        background-color: #FFF;
        height:auto;
        padding-top: 20px;
        padding-bottom: 20px;

        .row
        {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        label
        {
            text-align: left !important;
            font-weight: bold !important;
            font-size: 15px !important;
        }

        .text-right
        {
            text-align: right !important;
            padding-right: 0px;
            vertical-align: middle;
            margin-top: 10px;
        }
        .text-left
        {
            text-align: left !important;
        }
        textarea
        {
           
            resize: none;
            height:100px;
            outline-color: $grey-dark-border;
            border-color: $grey-dark-border;
            border-radius: 3px;
        }
        .text-area {
            border: none;
                &:focus
                {
                    outline-color: transparent;
                }
            resize: none;
            height:100px;
            outline-color: $grey-dark-border;
            border-color: $grey-dark-border;
            border-radius: 3px;
        }

        th
        {
            border: none;
        }

        #custom-file
        {
            visibility: hidden;
            display: none;
        }
        
        .custom-file
        {
            visibility: hidden;
            display: none;
        }

        .subtitle-section
        {
            margin-top:40px;
            font-weight: bold;
            text-align: left;
        }

        .nota-indicadores {
            background-color: #eaff23;
            display: inline-block; /* Cambia el contenedor a inline-block para ajustar al contenido */
            min-height: 30px;
            border: 1px solid #e7b713;
            border-radius: .25rem;
            text-align: justify;
            line-height: 1; /* Ajuste del line-height */
            font-size: clamp(10px, 1.5vw, 14px); /* Ajuste más pequeño del tamaño de fuente */
            padding: 10px;
            max-width: 100%; /* Limita el ancho máximo para que no se desborde */
            overflow: hidden; /* Oculta cualquier desbordamiento */
           
            
        }

        .headers-table
        {
            border: solid thin $grey-dark-border;
            border-radius:3px 3px 0px 0px;
        }
        .separator
        {
            border: solid thin $blue-border;
            margin-top: 20px;
            margin-bottom: 5px;
        }
        .note
        {
            font-size: 12px;
            color: $notes !important;
        }
        .select-custom
        {
            border: none;
            outline-color: transparent !important;
            outline: 0px !important;
            &:focus
            {
                border: none;
                outline-color: transparent !important;
                outline: 0px !important;
            }
        }

        .results-investment
        {
            border: solid thin $grey-dark-border;
            font-weight: bold;
        }
        .map-wrapper
        {
            min-height: 300px;
        }

        .not-padding
        {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }

        .grey-border
        {
            border-width: 1px 0px 0px 0px;
            border-color: $grey-dark-border;
        }
        .template-row
        {
            border: solid thin $grey-dark-border;
            border-width: 0px thin thin thin;
            border-radius:0px 0px 0px 0px;
            padding-top: 0px;
            padding-bottom: 0px;
            
            &:last-child
            {
                border-radius:0px 0px 3px 3px;
            }

            div
            {
                border: solid thin $grey-dark-border;
                border-width: 0px thin 0px 0px;
                height: 100%;
                margin-top: 0px;
                padding-top: 2px;
                padding-bottom: 2px;

                &:last-child
                {
                    border-width: 0px;
                }

                input,
                select
                {
                    border: none;
                }
            }
        }
        .btn-link{
            color:#2C70B9;
        }
        .delete-row
        {
            color: $danger !important;
            &:hover
            {
                transition: all ease-in-out 0.3s;
                color: $danger-hover;
            }
        }

        .table-not-padding
        {
            tr{
                td
                {
                    padding: .15rem !important;
                }
            }
            input,
            select
            {
                border: none;
                &:focus
                {
                    outline-color: transparent;
                }
            }
            textarea,select
        {
            border: none;
                &:focus
                {
                    outline-color: transparent;
                }
            resize: none;
            height:60px;
            outline-color: $grey-dark-border;
            border-color: $grey-dark-border;
            border-radius: 3px;
        }

        }
    }
    .last-wrapper
    {
        margin-bottom: 40px !important;
        border-bottom: 4px solid $blue-border;
    }
    .btn-link
    {
        svg
        {
            font-size: 21px;
        }
    }
    .fileName
    {
        color:rgb(44, 112, 185);
    }

    td
    {
        input
        {
            &:disabled
            {
                background-color: transparent;
            }
        }
    }

    .float-button
    {
        width: 40px;
        height: 40px;
        border-radius: 90px;
        line-height: 35px;
        background-color: #2C70B9;
        position: fixed;
        float: right;
        right: 20px;
        bottom: 30px;

        &:hover{
            background-color: #4589d3;
        }

        .btn-float
        {
            background-color: transparent;
            color: #FFF;
        }
    }

    .float-button-comments
    {
        width: 35px;
        height: 35px;
        border-radius: 90px;
        line-height: 30px;
        background-color: #2C70B9;
        position: fixed;
        float: right;
        right: 16.25% !important;
        bottom: 30px;

        &:hover{
            background-color: #4589d3;
        }

        .btn-float
        {
            background-color: transparent;
            color: #FFF;
        }
    }

    .btn-circle
    {
        height:40px;
        width: 40px;
        line-height: 18px;
        margin-top: 2px;
        margin-bottom: 3px;
        border-radius: 90px;
        text-align: center;
        margin-left: 2px;
        margin-right:  2px;
    }

    .btn-black
    {
        background-color: #6a6a6a;
        color: #FFF;
        &:hover
        {
            background-color: #000;
        }
    }
    .btn-orange
    {
        background-color: #FBA600;
        color: #FFF;
        &:hover
        {
            background-color: #dd9300;
        }

        .icon
        {
            transform: translate(-16%, 3%);
        }
    }
    .alert-message
    {
        border: 3px solid;
        background-color: #FFF;
        
        border-color: #C41622;
        margin-bottom: 10px;
        padding: 10px 0px;
        border-radius: 5px;
    }
    .row-file
    {
        &:nth-child(odd)
        {
            background-color: #f6f6f6;
        }
        &:hover
        {
            background-color: rgba(69, 137, 211, 0.2);
        }
        .file-icon
        {
            color: #4589d3;
            font-size: 16px;
        }
        
        .file-size,
        .file-name,
        .file-download
        {
            color: #2f2f2f;;
        }
        .file-download
        {
            &:hover
            {
                color: #4589d3; 
            }
        }
        .btn-custom-delete
        {
            background-color: transparent;
            color: #2f2f2f;

            &:hover{
                color: #C41622;
            }
        }
        .text-collapsed
        {
            display:flex;

            span
            {
                flex-shrink: 1;
                flex-grow: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    
}

.loader
{
    width: 100vw;
    height: 100vh;
    background-color: rgb(0,0,0);
    opacity: 0.75;
    position: fixed;
    z-index: 1000;
    
    .loader-wrap
    {
        margin-top: 40vh;

        p
        {
            margin-top: 10px;
            color: #fff;
        }
    }
}

.container-text
{
    width: 98% !important;
    white-space: nowrap;
    overflow: hidden;
    padding: 2px 5px;

    .longText
    {
        text-overflow: ellipsis !important;
    }
}

.section-approved
{
    font-size: 10px;
    background-color: #2da000;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 3px;
}

.force-table
{
    font-size: 11px !important;

    input, span{
        font-size: 11px !important;
    }
}