.Menu {
    min-height: 100vh;
    background-color: #ffffff;
    position: fixed;
    padding-left: 0px !important;
    padding-right: 0px !important;
    box-shadow: 0px 1px 6px #00000029;
    z-index: 4;

    @media (min-width: 480px) {
        min-height: 85vh; // Ajuste para pantallas medianas
    }

    @media (min-width: 768px) {
        min-height: 90vh; // Valor estándar para tablets y superiores
    }

    @media (min-width: 1140px) {
        min-height: 95vh; // Ampliar para pantallas grandes
    }

    @media (min-width: 1400px) {
        min-height: 100vh; // Máxima altura para pantallas grandes
    }
}

.avatar {
    height: 25px;
    width: 25px;
    border-radius: 90px;
    color: #FFF;
    margin: 10px;
    text-align: center;
    line-height: 24px;
    font-size: 12px !important;
    position: relative;
    vertical-align: middle;
}

.navbar {
    width: 100%;
    height: auto;
    min-height: 70vh;
    margin-top: 0;
    display: block !important;
    padding: 0px 0px !important;
    box-shadow: none !important;

    @media (min-width: 480px) {
        min-height: 85vh; // Ajuste para pantallas medianas
    }

    @media (min-width: 768px) {
        min-height: 90vh; // Valor estándar para tablets y superiores
    }

    @media (min-width: 1140px) {
        min-height: 95vh; // Ampliar para pantallas grandes
    }

    @media (min-width: 1400px) {
        min-height: 100vh; // Máxima altura para pantallas grandes
    }

    

    a {
        width: 100%;
        display: block;
        text-decoration: none !important;
        color: #222;
        text-align: left;
        padding: 15px 15px;
        border-right: solid 4px transparent;

        &:hover {
            background-color: #ececec;
            border-right: solid 4px #2C70B9;
            transition: all ease-in-out 0.3s;
        }
    }

    .activeLink {
        background-color: #ececec;
        border-right: solid 3px #2C70B9;
        font-weight: bold;
    }
}

.user-header {
    width: calc(100%-20px);
    margin: 0px !important;
    padding: 0px 5px;
    border-bottom: solid thin rgb(224, 224, 224);

    label {
        font-size: 12px;
        line-height: 50px;
        height: 20px;
    }

    .icon {
        line-height: 50px;
        align-content: flex-end;
        height: 30px;
    }
}

.logout {
    position: absolute;
    bottom: 20px;
    width: 100%;
}

.btn-primary-custom {
    background-color: rgb(232, 246, 255) !important;
    color: #2C70B9 !important;
    font-weight: bold !important;
    box-shadow: none !important;
}

#navbarSupportedContent {
    display: block !important;
}

.navbar-collapse {
    display: block !important;

    .navbar-nav {
        flex-direction: column !important;
    }
}

