$grey-text: #7b7b7b;
.TopPPIS {
    position: relative;
    background-color: white;
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 112px;

    // Media queries para ajustar la altura
    @media (min-width: 480px) {
        height: 120px; // Ajuste ligero para pantallas medianas
    }

    @media (min-width: 768px) {
        height: 140px; // Altura para tablets
    }

    @media (min-width: 1140px) {
        height: 160px; // Altura para pantallas más grandes
    }

    @media (min-width: 1400px) {
        height: 180px; // Altura máxima en pantallas grandes
    }

    .header-options {
        text-align: left;
        position: absolute;
        bottom: 0;

        .header-option {
            font-size: 16px;
            padding: 0 30px;
            margin-right: 10px;
            display: inline-block;
            border-bottom: 4px solid #2C70B9;
            font-weight: bold;

            // Media query para ajustar el tamaño de fuente en pantallas más pequeñas
            @media (max-width: 480px) {
                font-size: 14px;
                padding: 0 15px;
            }
        }
    }

    .top-header-buttons {
        position: absolute;
        right: 50px;
        bottom: 5px;

        .btn-link-custom {
            background-color: transparent !important;
            line-height: 20px;
            color: $grey-text !important;
            vertical-align: middle;
            display: flexbox;
            margin: 0 15px;
            padding: 5px 15px;

            // Ajuste para pantallas pequeñas
            @media (max-width: 480px) {
                margin: 0 10px;
                padding: 5px 10px;
            }
        }

        .btn-primary {
            margin: 0 5px 0 15px;

            // Media query para pantallas pequeñas
            @media (max-width: 480px) {
                margin: 0 5px;
            }
        }

        a {
            color: #FFF !important;
            padding: 5px 20px !important;
            text-decoration: none !important;

            .btn-primary {
                color: #FFF;
            }

            // Ajuste de padding para pantallas más pequeñas
            @media (max-width: 480px) {
                padding: 5px 15px !important;
            }
        }
    }
}
