$grey-light: #F9F9F9;
$text-primary: #585858;
$grey-text: #dddddd;
$danger: #e90000;
$grey-secondary: #3d3d3d;
$grey-secondary-bg: #dddddd;

.SideBarComments{
    .comments-bar {
        background-color: #FFF;
        text-align: left;
        z-index: 100;
        padding-left: 0px !important;
        padding-right: 0px !important;

        .header {
            background-color: $grey-light;
            height: 72px;
            font-weight: bold;
            display: flex;
            align-items: flex-end;
            border-bottom: 1px solid black
        }
        display:flex;
        flex-flow: row nowrap;
        flex-flow: column wrap;

        .noComments
        {
            height: 40vh;
            margin-top: 40%;
            text-align: center;
            .row
            {
                margin: 0px;
            }
            .icon-no-comments
            {
                text-align: center;
                
                .icon
                {
                    font-size: 72px;
                    color: $grey-text;
                }
            }

            strong
            {
                font-size: 10pt;                
            }

            .info-text
            {
                font-size: 10pt;
                margin-top: 20px;

                p
                {
                    text-align: justify;
                }

            }
        }

        .no-spacing
        {
            margin: 0px;
        }

        .buttons-block
        {
            position: absolute;
            bottom: 0;
            margin-bottom: 20px;

            button
            {
                margin-bottom: 5px;
                font-size: 12px;
            }
        }

        .title-origin
        {
            font-weight: bold;
            background-color: #EEE;
            padding: 5px 10px;
        }

        .comments-list
        {
            max-height: 65%;
            //border: 1px;
            overflow-y: scroll;
        }

        .comment-wrap
        {
            padding: 12px 30px;
            text-align: justify;
            border-bottom: thin solid #dedede;

            &:hover
            {
                cursor: pointer;
                background-color: #e4f5ff;
            }
        }

        .row-customized
        {
            min-width: 100% !important;
        }
    }
    .custom-badge
    {
        text-align:center !important;
        padding: 5px 10px !important;
        border-radius: 5px;
    }

    .secondary
    {
        background-color: $grey-secondary-bg;
        color: $grey-secondary;
    }
    .date
    {
        margin-top: -10px;
        margin-bottom: 5px;
        .small, small
        {
            font-weight: bold !important;
        }
    }
}

.modal
{
    .icon{
        font-size: 48px;
        color: $danger;
    }

    .icon-warning
    {
        font-size: 48px;
        color: #FB9C23;
    }

    .status-badge
    {
        border-radius: 3px;
    }
    
    .button-right
    {
        float: right !important;
    
        .btn-primary
        {
            float: right !important;
        }
    }
    .btn-content
    {
        div
        {
            margin-left: 5px !important;
            margin-right: 5px !important;
        }
    }
}
