.wrap-login {
    min-height: 100vh;
}

#login {
    overflow-y: auto;

    .login-top {
        // Altura predeterminada para pantallas pequeñas
        height: 150px;

        // Media queries para diferentes anchos de pantalla
        @media (min-width: 480px) {
            height: 150px; // Ajustar ligeramente para pantallas medianas
        }

        @media (min-width: 768px) {
            height: 175px; // Altura estándar para tablets y superiores
        }

        @media (min-width: 1140px) {
            height: 200px; // Altura ampliada para pantallas más grandes
        }

        @media (min-width: 1400px) {
            height: 220px; // Máxima altura en pantallas grandes
        }
    }

    .modal-login {
        h5 {
            font-size: 32px;
        }

        &.modal-content {
            margin: 0 auto;
            width: 500px;
            padding: 0;
            border-radius: 0 !important;
            border: 0;
        }

        #recovery {
            width: 280px;
            margin: 0 auto;
        }
    }

    .session-check {
        vertical-align: 24px;
        line-height: 0;
    }
}

.bg-image {
    background-color: #EEEEEE;
    min-height: 90vh;
}