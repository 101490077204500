$blue-primary: #2C70B9;
$green-primary: #269c21;
$red-primary: #b92c2c;
$blue-primary-bg: #2C70B922;
$grey-secondary: #3d3d3d;
$grey-secondary-bg: #dddddd;
$grey-secondary-hover: #e1e1e1;

.Catalogs
{
    background-color: #fff;
    .wrapper
    {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    
    .window-block
    {
        top: 25vh; 
        max-width: 532px;
        position: relative;
        width:100%;
    
        .btn-primary
        {
            .size-20
            {
                vertical-align:-3px
            }
        }
    }

    .wrap-cards
    {
        margin-top: 60px;

        .catalogs{
            text-decoration: none !important;
            color: #000;
            font-weight: bold;
        }

        .card
        {
            margin-top: 10px;
            padding: 20px;
            min-height: 100px;
            line-height: 30px;

            &:hover
            {
                background-color: $blue-primary;
                color: #fff;
                svg
                {
                    color: #fff !important;
                }
            }
        }
    }

    .list
    {
        margin-top: 40px;
    }

    //Modal

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
     width: 1900px;
        // width: 400px;
        max-width: 100%;
        max-height: 80vh; /* Limita la altura para que sea responsiva */
        overflow-y: auto; /* Activa la barra de desplazamiento vertical */
    }

    .modal-content.small-modal {
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        // width: 1900px;
        width: 300px;
        max-width: 100%;
        max-height: 80vh; /* Limita la altura para que sea responsiva */
        overflow-y: auto; /* Activa la barra de desplazamiento vertical */
      }

    .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.cancel-button {
    background-color: #a93226;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.cancel-button:hover {
    background-color: #a93226;
}


    .wrap-dependency
    {
        margin-top: 40px;
        background-color: #fff;
        min-height: 240px;
        padding: 40px;

        .row
        {
            margin-bottom: 20px;
        }
        

        label
        {
            text-align: left;
        }
    }

    .insert,
    .saved
    {
        background-color: $blue-primary;
        color: #fff;
        border-radius: 4px;
    }

    .update,
    .updated,
    .approved{
        background-color: $green-primary;
        color: #fff;
        border-radius: 4px;
    }
    .sent
    {
        background-color: $blue-primary;
        color: #fff;
        border-radius: 4px;
    }

    .delete
    {
        background-color: $red-primary;
        color: #fff;
        border-radius: 4px;
    }
}