$orange: #faa700;
$danger: #C41622;
.ChoosPPI
{
    padding: 0px !important;

    .warnin-message
    {
        max-width: calc(100% - 80px);
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        min-height: 60px;
        line-height: 60px;
        border: solid $danger 2px;
        border-radius: 3px;
        background-color:#FFF;
        margin-bottom: 20px;

        .icon-danger
        {
            color: $danger;
            font-size: 28pt;
            vertical-align:middle;
        }
    }
    .window-block
    {
        max-width: calc(100% - 80px);
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        border: solid #dee2e6 2px;
        
        .no-padding
        {
            padding-left:0px !important;
            padding-right: 0px !important;
        }
        .divider
        {
            border: solid #dee2e6;
            border-width: 0px 0px 1px 0px;
            margin-bottom: 20px;
        }

        .border-text
        {
            line-height:2.5;
        }

        .mx-auto
        {
            max-width: 600px;
        }

        .description-content
        {
            text-align: justify;
        }

        .button-comment
        {
            position: relative;
            margin-top: -20px;
            right: 15px;
            float: right;
            background-color: $orange;
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 90px;
            cursor: pointer;

            .icon
            {
                color: #FFF;
                line-height: 35px;
                font-size: 16px;
            }
        }

        .comments
        {
            height: 150px;
            resize: none;
        }

        .description
        {
            min-height: 140px;
            resize: none;
        }

        .buttons-wrap
        {
            display:flex;
            .btn.btn-primary {
                background-color: transparent !important;
                border-color: #dee2e6 !important;
                color: #555;
            }
            .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle 
            {
                color: #fff !important;
                background-color: #0062cc !important;
                border-color: #005cbf !important;
            }
        }
        .admin-alert-message
        {
            text-align: center !important;
            font-weight: bold;
        }
    }
}