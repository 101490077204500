.wrap-login
{
    min-height: 100vh;
}

#login {
    overflow-y:auto;
    .login-top {
        height:175px;
    }
    .modal-login {
        h5 {
            font-size:32px;
        }
        &.modal-content {
            margin: 0px auto;
            width: 500px;
            padding: 0 0px;
            border-radius: 0px !important;
            border: 0;
        }

        #reset{
            width: 280px !important;
            margin: 0px auto !important;
        }

        #reset-error
        {
            
            width: 280px !important;
            margin: 0px auto !important;

            .icon
            {
                background-color: #ffe0e0;
                color: #ff5757;
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 90px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
            }

            a{
                text-decoration: none !important;
            }
        }
    }

    .session-check {
        vertical-align: 24px;
        line-height: 0;
    }
}

.bg-image {
    background-color: #EEEEEE;
    min-height: 90vh;
}

.paragraph{
    margin-top: 30px;
    width: 100%;
    position: relative;
    display: flex;
}

.top-10{
    margin-top: 10px;
}