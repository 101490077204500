$blue-link: #e3f5ff;
$blue-primary: #2C70B9;
$orange-badge-bg: #fff3d3;
$orange-badge-font: #ffa807;
$orange-custom-badge-bg: #ffa807;
$orange-custom-badge-font: #fff;
$secondary-badge-bg: #dddddd;
$secondary-badge-font: #3d3d3d; 
$success-badge-bg: #dceee1;
$success-badge-font: #28a745; 


.custom-badge
{
    text-align:center !important;
    padding: 5px 10px !important;
    border-radius: 3px;
    max-width: 120px;
    margin-bottom: 10px;
}

.button-ppi
{
    height: 20px !important;
    line-height: 10px !important;
    margin-top: -4px !important;

    a
    {
        text-decoration: none !important;
    }
}

.primary
{
    background-color: $blue-link;
    color: $blue-primary;
}

.warning
{
    background-color: $orange-badge-bg;
    color: $orange-badge-font;
}

.secondary
{
    background-color: $secondary-badge-bg;
    color: $secondary-badge-font;
}

.success
{
    background-color: $success-badge-bg;
    color: $success-badge-font;
}